<template>
    <div class="w-1/5 bg-blue-700 fixed top-0 left-0 h-screen block py-3" v-if="sidebar.Menu">
        <div class="flex w-full justify-center">
            <img src="https://www.pavilionweb.com/wp-content/uploads/2017/03/man-300x300.png" width="150px" alt="">
        </div>
        <div class="block text-center">
            <span class="text-white text-xl">{{ company && company.User && company.User.Name ? company.User.Name : '' }}</span>
        </div>
        <div class="pl-2 block">
            <div class="flex p-3 my-auto relative rounded-l-full" :class="$route.path === menu.Url ? 'bg-white text-blue-700' : 'text-white'" v-for="(menu, n) in sidebar.Menu" :key="n">
                <div class="absolute right-0 triangle-up" style="top: -20px;" v-if="$route.path === menu.Url"></div>
                <div class="absolute right-0 triangle-down" style="bottom: -20px;" v-if="$route.path === menu.Url"></div>
                <b-icon :icon="menu.Icon" class="my-auto" custom-size="mdi-24px"></b-icon>
                <span class="text-lg my-auto ml-3 font-bold">{{ menu.Name }}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        sidebar () {
            return this.$sidebar.value
        }
    }
}
</script>
<style lang="scss" scoped>
    .triangle-up {
        border-left: 20px solid transparent;
        border-right: 0px solid transparent;
        border-bottom: 20px solid white;
    }
    .triangle-down {
        border-left: 20px solid transparent;
        border-right: 0px solid transparent;
        border-top: 20px solid white;
    }
    .hover-custom:hover {
        background: rgba(222, 222, 222, 0.3);
    }
</style>
